@font-face {
  font-family: Bolden-Display !important;
  src: url("../public/Bolden-Display.otf") format("opentype");
  font-weight: bold;
}

body{
  background-color: black !important;
}
#outerPanel{
  height: 100vh !important;
}
.bgcolor{
  background-color: black;
}

/*Smart Watch*/

@media only screen and (max-width: 200px) {


  #videoPanelAnimation{
    height: 200% !important;
    width: 200% !important;
    top: -50% !important;
    left: -50% !important;
  }

  #counterPanel{
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    border: transparent;
    background-color: transparent;
    box-shadow: transparent;
    color: white;
    left: 50%;
    top: 50%;
    margin-top: -60px;
    margin-left: -60px;
  
  }

  .show-counter{
    position: absolute !important;
    width: 100% !important;
    height: 300px;
    top: 50% !important;
    margin-top: -70px;
    text-align: center !important;  

  }
  
  .countdown{
    width: 50% !important;
    display: inline-block !important;
    padding: 0px !important;
  } 
  
  .show-counter .timer-val{
    display: inline-block;
    font-size: 30px;
    font-weight: bolder;
   } 

 .show-counter .timer-dot{  
  font-size: 30px;  
  padding-left: 16px;
  font-weight: bolder;
  visibility: hidden;
 }

 .time-desc{
  font-size: 15px;
  font-weight: normal;
  text-align: left !important;
}
  
}

/*Small Display Mobile*/
@media only screen and (min-width: 200px) and (max-width: 380px) {

  #videoPanelAnimation{
    height: 200% !important;
    width: 200% !important;
    top: -50% !important;
    left: -50% !important;
  }

  #counterPanel{
    position: absolute;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: transparent;
    background-color: transparent;
    box-shadow: transparent;
    color: white;
    left: 50%;
    top: 50%;
    margin-top: -150px;
    margin-left: -150px;
  
  }

  .show-counter{
    position: absolute !important;
    width: 100% !important;
    height: 300px;
    top: 50% !important;
    margin-top: -150px;
    text-align: center !important;  

  }
  
  .countdown{
    width: 50% !important;
    display: inline-block !important;
    padding: 0px !important;
  } 
  
  .show-counter .timer-val{
    display: inline-block;
    font-size: 70px;
    font-weight: bolder;
   } 

 .show-counter .timer-dot{  
  font-size: 70px;  
  padding-left: 16px;
  font-weight: bolder;
  visibility: hidden;
 }

 .time-desc{
  font-size: 30px;
  font-weight: normal;
  text-align: left !important;
}
  
}


/*mobile*/

@media only screen and (min-width: 380px) and (max-width: 481px) {

  #videoPanelAnimation{
    height: 200% !important;
    width: 200% !important;
    top: -50% !important;
    left: -50% !important;
  }

  #counterPanel{
    position: absolute;
    width: 350px;
    height: 350px;
    border-radius: 50%;
    border: transparent;
    background-color: transparent;
    box-shadow: transparent;
    color: white;
    left: 50%;
    top: 50%;
    margin-top: -175px;
    margin-left: -175px;
  
  }

  .show-counter{
    position: absolute !important;
    width: 100% !important;
    height: 300px;
    top: 50% !important;
    margin-top: -200px;
    text-align: center !important;  

  }
  
  .countdown{
    width: 50% !important;
    display: inline-block !important;
    padding: 0px !important;
  } 
  
  .show-counter .timer-val{
    display: inline-block;
    font-size: 90px;
    font-weight: bolder;
   } 

 .show-counter .timer-dot{  
  font-size: 90px;  
  padding-left: 16px;
  font-weight: bolder;
  visibility: hidden;
 }

 .time-desc{
  font-size: 35px;
  font-weight: normal;
  text-align: left !important;
}

  

  
}
/* Phablets */

@media only screen and (min-width: 482px) and (max-width: 767px)  {

  #counterPanel{
    width: 500px !important;
    height: 500px !important;
    margin-top: -250px !important;
    margin-left: -250px !important;
  
  }
  .show-counter .countdown > p {
    margin: 0;
    font-size: 40px !important;
      font-weight: bolder;
  }
  
  .show-counter .countdown > span {
    text-transform: uppercase;
    line-height: 40px !important;
    font-size: 20px;
    font-weight: bolder;
    
  }
  
  .timer-sep{
    font-size: 30px !important;
    margin-bottom: 3rem !important;
  }
  .time-desc{
    visibility: visible !important;
    font-size: 16px !important;
  }
 

  
}


/*Tablets*/

@media only screen and (min-width: 768px) and (max-width: 1100px)  {

  
 
  #videoPanelAnimation{
    height: 200% !important;
    width: 200% !important;
    top: -50% !important;
    left: -50% !important;
  }

  #counterPanel{
    position: absolute;
    width: 500px !important;
    height: 500px !important;
    border-radius: 50%;
    border: transparent;
    background-color: transparent;
    box-shadow: transparent;
    color: white;
    left: 50%;
    top: 50%;
    margin-top: -250px !important;
    margin-left: -250px !important;
  
  }

  .show-counter{
    position: absolute !important;
    width: 100% !important;
    height: 300px;
    top: 50% !important;
    margin-top: -250px;
    text-align: center !important;  

  }
  
  .countdown{
    width: 50% !important;
    display: inline-block !important;
    padding: 0px !important;
  } 
  
  .show-counter .timer-val{
    display: inline-block;
    font-size: 120px;
    font-weight: bolder;
   } 

 .show-counter .timer-dot{  
  font-size: 120px;  
  padding-left: 16px;
  font-weight: bolder;
  visibility: hidden;
 }

 .time-desc{
  font-size: 60px;
  font-weight: normal;
  text-align: left !important;
}
 
  
}

/*Laptopns*/
@media only screen and (min-width: 1101px) {
  #counterPanel{
    position: absolute;
    width: 600px;
    height: 600px;
    border-radius: 50%;
    border: transparent;
    background-color: transparent;
    box-shadow: transparent;
    color: white;
    left: 50%;
    top: 50%;
    margin-top: -300px;
    margin-left: -300px;
  
  }

  .show-counter{
    position: absolute !important;
    width: 100% !important;
    height: 100px;
    top: 50% !important;
    margin-top: -50px;
    text-align: center !important;     
  }
  
  .countdown{
    width: 25% !important;
    display: inline-block !important;
    padding: 0px !important;
  } 
  
  .show-counter .timer-val{
    display: inline-block;
    font-size: 90px;
    font-weight: bolder;
   }
 .show-counter .timer-dot{  
  font-size: 90px;  
  padding-left: 16px;
  font-weight: bolder;
 }

 .time-desc{
  visibility: hidden;
  font-size: 20px;
  font-weight: normal;
}
 
}